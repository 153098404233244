<template>
    <div class="row">
        <div class="col-md-12">
            <seccion-datos :titulo="datosempresa" @cambios="guardar(id)">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <!-- No podrá editarse este campo, solo en la creación -->
                            <div class="form-group">
                                <label>{{ $t('general.empresa') }}&nbsp;</label>
                                <input type="text" class="form-control" v-model="nombre_empresa">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('general.nombresms') }}</label>
                            <input type="text" class="form-control" v-model="nombre_sms">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>NIF/CIF</label>
                                    <input type="text" v-model="nif_empresa" class="form-control">
                                </div>

                                <div class="col-md-4">
                                    <label>{{ $t('general.tipodepersona') }}</label>
                                    <select v-model="tipo_persona_empresa" class="form-control select">
                                        <option value="F">{{ $t('general.fisica') }}</option>
                                        <option value="J">{{ $t('general.juridica') }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label>{{ $t('general.codigopais') }}</label>
                                    <input type="text" class="form-control" v-model="codigo_pais_empresa">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ $t('general.nombre') }}</label>
                                    <input type="text" v-model="nombre_fisica_empresa" class="form-control">

                                </div>
                                <div class="col-md-4">
                                    <label>{{ $t('general.apellido') }} 1</label>
                                    <input type="text" class="form-control" v-model="apellido1_fisica_empresa">
                                </div>
                                <div class="col-md-4">
                                    <label>{{ $t('general.apellido') }} 2</label>
                                    <input type="text" class="form-control" v-model="apellido2_fisica_empresa">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>{{ $t('general.direccion') }}</label>
                            <input type="text" class="form-control" v-model="direccion_empresa">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ $t('general.cpostal') }}</label>
                            <input type="text" class="form-control" v-model="cp_empresa">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ $t('general.ciudad') }}</label>
                            <input type="text" class="form-control" v-model="ciudad_empresa">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ $t('general.provincia') }}</label>
                            <input type="text" class="form-control" v-model="provincia_empresa">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ $t('general.tipoderesidencia') }}</label>
                            <select v-model="tipo_residencia_empresa" class="form-control select">
                                <option value="R">{{ $t('general.residente') }}</option>
                                <option value="U">{{ $t('general.unioneuropea') }}</option>
                                <option value="E">{{ $t('general.extranjero') }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ $t('general.telefono') }}</label>
                            <input type="text" class="form-control" v-model="telefono_empresa">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" v-model="email_empresa">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Fax</label>
                            <input type="text" class="form-control" v-model="fax_empresa">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">

                            <label>Protección datos</label>

                            <textarea v-model="protecciondatos_empresa" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <FileUpload mode="basic" :maxFileSize="20000000" @select="subirarchivo($event)" :auto="true"
                        chooseLabel="Logo" />
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import FileUpload from 'primevue/fileupload';

export default {
    components: {

        FileUpload,
    },
    data() {
        return {
            empresa: '',
            nombre_empresa: '',
            nombre_comercial: '',
            direccion_empresa: '',
            cp_empresa: '',
            ciudad_empresa: '',
            provincia_empresa: '',
            telefono_empresa: '',
            nif_empresa: '',
            logo: '',
            tipo_residencia_empresa: '',
            tipo_persona_empresa: '',
            codigo_pais_empresa: '',
            nombre_fisica_empresa: '',
            apellido1_fisica_empresa: '',
            apellido2_fisica_empresa: '',
            tipo_identificacion: '',
            protecciondatos_empresa: '',
            email_empresa:'',
            gestion_adjuntos_email: '',
            seleccionado: '',
            datosempresa: "Datos Empresa",
            nombre_sms: '',
            fax_empresa: '',
        }
    },
    methods: {

        async obtenerEmpresa() {
            const api = new PwgsApi;
            const datos = await api.get('empresa');
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.nombre_empresa = this.empresa.nombre_empresa;
            this.nombre_comercial = this.empresa.nombre_comercial;
            this.direccion_empresa = this.empresa.direccion_empresa;
            this.cp_empresa = this.empresa.cp_empresa;
            this.ciudad_empresa = this.empresa.ciudad_empresa;
            this.provincia_empresa = this.empresa.provincia_empresa;
            this.telefono_empresa = this.empresa.telefono_empresa;
            this.nif_empresa = this.empresa.nif_empresa;
            this.tipo_residencia_empresa = this.empresa.tipo_residencia_empresa;
            this.tipo_persona_empresa = this.empresa.tipo_persona_empresa;
            this.codigo_pais_empresa = this.empresa.codigo_pais_empresa;
            this.nombre_fisica_empresa = this.empresa.nombre_fisica_empresa;
            this.apellido1_fisica_empresa = this.empresa.apellido1_fisica_empresa;
            this.apellidos2_fisica_empresa = this.empresa.apellidos2_fisica_empresa;
            this.gestion_adjuntos_email = this.empresa.gestion_adjuntos_email;
            this.email_empresa = this.empresa.email_empresa;
            this.nombre_sms = this.empresa.nombre_sms;
            this.fax_empresa = this.empresa.fax_empresa;
            this.protecciondatos_empresa = this.empresa.protecciondatos_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.datosempresa = this.$t('general.datosempresa');
        },

        async subirarchivo(event) {
            const formData = new FormData();
            formData.append('logo', event.files[0]);
            const api = new PwgsApi();
            const resp = await api.post('empresa/1/logo',formData);
            if (resp == 'OK') {
                this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Logo adjuntado.', life: 2000 });
            } else {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al adjuntar.', life: 5000 });
            }
        },


        async guardar() {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { protecciondatos_empresa: this.protecciondatos_empresa, email_empresa: this.email_empresa, nombre_empresa: this.nombre_empresa, nombre_comercial: this.nombre_comercial, direccion_empresa: this.direccion_empresa, cp_empresa: this.cp_empresa, ciudad_empresa: this.ciudad_empresa, provincia_empresa: this.provincia_empresa, telefono_empresa: this.telefono_empresa, nif_empresa: this.nif_empresa, logo: this.logo, tipo_residencia_empresa: this.tipo_residencia_empresa, tipo_persona_empresa: this.tipo_persona_empresa, codigo_pais_empresa: this.codigo_pais_empresa, nombre_fisica_empresa: this.nombre_fisica_empresa, apellido1_fisica_empresa: this.apellido1_fisica_empresa, apellidos2_fisica_empresa: this.apellidos2_fisica_empresa }
            const api = new PwgsApi;
            try {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('empresa/1', subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.obtenerEmpresa();
            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    mounted() {
       this.obtenerEmpresa();
    },
    watch: {
        id() {
            //this.obtenerEmpresa();
        }
    }
}
</script>